<template>
  <div class="page-item-wrap">
    <main class="sub __login">
      <div class="inner __join-wrap">
        <div class="tit req-type">
          <h1>회원가입</h1>
          <p class="stit">호구153에 오신 것을 환영합니다.</p>
        </div>
        <div class="sub-cont">
          <div class="link-tab-join">
            <a
              href="javascript:void(0)"
              class="link"
              :class="{ active: tabView === PERSONAL }"
              @click="tabChange(PERSONAL)"
              >개인회원</a
            >
            <a
              href="javascript:void(0)"
              class="link"
              :class="{ active: tabView === ENTERPRISE }"
              @click="tabChange(ENTERPRISE)"
              >기업회원</a
            >
          </div>
          <personal-tab
            v-if="tabView === PERSONAL"
            :pass="pass"
            :passId="passId"
            :passEmail="passEmail"
            :passName="passName"
            :passGender="passGender"
            :passPhoneNo="passPhoneNo"
            :passBirthDay="passBirthDay"
          ></personal-tab>
          <enterprise-tab v-else></enterprise-tab>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import PersonalTab from 'Components/join/partials/Personal'
import EnterpriseTab from 'Components/join/partials/Enterprise'
import { sendMessageToApp } from 'Utils/message'

const PERSONAL = 'cGVyc29uYWw%3D'
const ENTERPRISE = 'qB225%7123%21'

export default {
  components: {
    PersonalTab,
    EnterpriseTab,
  },
  props: {
    pass: {
      type: String,
    },
    passId: {
      type: String,
    },
    passEmail: {
      type: String,
    },
    passName: {
      type: String,
    },
    passGender: {
      type: String,
    },
    passToken: {
      type: String,
    },
    passPhoneNo: {
      type: String,
    },
    passBirthDay: {
      type: String,
    },
  },
  data() {
    return {
      tabView: 'PERSONAL',
      PERSONAL: Object.freeze('PERSONAL'),
      ENTERPRISE: Object.freeze('ENTERPRISE'),
    }
  },
  methods: {
    tabChange(value) {
      this.tabView = value
    },
  },
  created() {
    if (!this.isApp) {
      //카카오 로그인으로 새창 열려서 회원가입 필요하여 리디렉트 되는 경우 유저 정보를 부모창으로 전달한다
      if (this.$route.query && window.opener) {
        //@TODO 카카오인지 네이버인지 구분 필요
        // 카카오일때/네이버일때
        if (this.$route.query.userInfo) {
          let name = ''
          const userInfo = JSON.parse(this.$route.query.userInfo)
          switch (userInfo.provider) {
            case 'NAVER':
              name = 'NAVER_REDIRECT'
              break
            case 'KAKAO':
              name = 'KAKAO_REDIRECT'
              break
          }
          window.opener.postMessage(
            {
              name,
              result: this.$route.path === '/join' ? 'JOIN' : 'LOGIN',
              userInfo,
              token: this.$route.query.token
                ? decodeURI(this.$route.query.token)
                : '',
            },
            '*', // <-- TODO:: 호구 도메인으로 한정시켜야 함
          )
          window.close()
        }

        // 본인인증일때
        else if (this.$route.query.certNum) {
          window.opener.postMessage(
            {
              name: 'KMCIS_REDIRECT',
              certNum: this.$route.query.certNum,
              rec_cert: this.$route.query.rec_cert,
            },
            '*', // <-- TODO:: 호구 도메인으로 한정시켜야 함
          )
          window.close()
        }
      }
    } else {
      const personalData = Cookies.get(PERSONAL)
      const enterpriseData = Cookies.get(ENTERPRISE)

      sendMessageToApp('LOG', {
        message: 'cookie check',
        personal: personalData ? atob(personalData) : 'nodata',
        enterprise: enterpriseData ? atob(enterpriseData) : 'nodata',
      })

      if (personalData) this.tabView = this.PERSONAL
      else if (enterpriseData) this.tabView = this.ENTERPRISE
    }
    //앱일때는 카카오는 Personal에서 직접 query를 이용해 처리
    //본인인증은 AuthAgreement에서 직접 query를 이용해 처리
  },
}
</script>

<style></style>
