<template>
  <div>
    <div
      v-bind:class="{ on: checkMobile }"
      v-if="!isKakao && !isNaver && !isCertCompleted"
      class="sns-join"
    >
      <p>소셜로 간편하게 로그인하세요.</p>
      <div class="btn-wrap">
        <button class="sns naver" type="button" @click="naverLogin">
          <span>네이버로그인</span>
        </button>
        <button class="sns kakao" type="button" @click="kakaoLogin">
          <span>카카오로그인</span>
        </button>
      </div>
    </div>
    <h2 class="h2-join">간편등록</h2>
    <div class="form-wrap">
      <pone-confirm
        isPersonal
        ref="poneConfirm"
        :isSns="isNaver || isKakao"
        :certedEmail="certedEmail"
        :certedUserNm="certedUserNm"
        :certedGender="certedGender"
        :certedPhone="certedPhone"
        :certedBirthday="certedBirthday"
        :userId="userId"
        :userPw="userPw"
        :userPwRepeat="userPwRepeat"
        :isCertCompleted="isCertCompleted"
        :isPhoneWrap="isPhoneWrap"
        :address="address"
        :addressDetail="addressDetail"
        @agreementChanged="onAgreementChanged"
        @certCompleted="onCertCompleted"
        @mobileCertRequest="onMobileCertRequestPersonal"
        @changeName="parentsNm"
      ></pone-confirm>
      <div class="input-form" v-if="isCertCompleted">
        <div class="t-head">아이디 <i class="required"></i></div>
        <input
          v-model="userId"
          type="text"
          class="text"
          ref="userId"
          placeholder="4 ~ 20자의 영문, 숫자와 특수문자(_)만 사용 가능합니다."
          @blur="chkDupUserId"
        />
      </div>
      <div class="input-form" v-if="isCertCompleted">
        <div class="t-head">비밀번호 <i class="required"></i></div>
        <input
          v-model="userPw"
          type="password"
          class="text"
          ref="userPw"
          placeholder="영문자/숫자/특수문자를 2가지 이상 조합하여 8자리 이상 입력"
          @blur="chkPwIsValid"
        />
      </div>
      <div class="input-form" v-if="isCertCompleted">
        <div class="t-head">비밀번호 확인 <i class="required"></i></div>
        <input
          v-model="userPwRepeat"
          type="password"
          class="text"
          ref="userPwRepeat"
          placeholder=""
          @blur="chkPwIsEqual"
        />
      </div>
      <div
        class="input-form"
        v-if="isNaver || isKakao || isCertCompleted"
        @click="findAddress"
      >
        <div class="t-head">주소 <i class="required"></i></div>
        <div class="address-form">
          <input
            v-model="address"
            type="text"
            class="text addr1"
            ref="address"
            readonly
          />
          <button type="button" class="btn-func">주소찾기</button>
        </div>
      </div>
      <div class="input-form" v-if="isNaver || isKakao || isCertCompleted">
        <div class="t-head">나머지 주소 <i class="required"></i></div>
        <input
          v-model="addressDetail"
          type="text"
          class="text inp addr2"
          ref="addressDetail"
        />
      </div>
      <hr />
      <!--div class="input-form">
        <div class="t-head">연락처 <i class="required"></i></div>
        <input
          v-model="phone"
          type="text"
          class="text"
          ref="phone"
          placeholder=""
          :readonly="isCertCompleted"
        />
      </!--div-->
      <!--div class="input-form">
        <div class="t-head">이메일 <i class="required"></i></div>
        <input
          v-model="email"
          type="text"
          class="text"
          ref="email"
          placeholder=""
          :readonly="isKakao || isNaver"
        />
      </!--div-->
      <!--div class="input-form">
        <div class="t-head">추천인 아이디</div>
        <input
          v-model="recommenderPhone"
          type="text"
          class="text"
          ref="recommenderPhone"
          placeholder=""
        />
      </!--div-->
    </div>
    <!--auth-agreement
      isPersonal
      :userNm="userNm"
      :phone="phone"
      :isSns="isNaver || isKakao"
      @agreementChanged="onAgreementChanged"
      @certCompleted="onCertCompleted"
      @mobileCertRequest="onMobileCertRequestPersonal"
    ></!--auth-agreement-->
    <auth-agreement
      isPersonal
      ref="authAgreement"
      :isSns="isNaver || isKakao"
      :certedEmail="certedEmail"
      :certedUserNm="certedUserNm"
      :certedGender="certedGender"
      :certedPhone="certedPhone"
      :certedBirthday="certedBirthday"
      :userId="userId"
      :userPw="userPw"
      :userPwRepeat="userPwRepeat"
      @agreementChanged="onAgreementChanged"
      @certCompleted="onCertCompleted"
      @mobileCertRequest="onMobileCertRequestPersonal"
    ></auth-agreement>

    <!-- 추가정보 입력란 -->
    <h2 class="h2-join">추가정보</h2>
    <div class="form-wrap">
      <div class="input-form">
        <div class="t-head">추천인</div>
        <input
          v-model="recommenderPhone"
          type="text"
          class="text"
          ref="recommenderPhone"
          placeholder="추천인 휴대폰번호를 (-)를 제외하고 입력하세요."
          @blur="chkRecoPhone"
        />
      </div>
      <!-- <div class="input-form">
        <div class="t-head">소속</div>
        <input
          v-model="affiliatedCompany"
          type="text"
          class="text"
          ref="affiliatedCompany"
          placeholder="소속회사명을 입력하세요."
          @blur="chkCompName"
        />
      </div> -->
    </div>
    <button class="btn __join" @click="requestJoin">회원가입</button>
  </div>
</template>

<script>
const URL = process.env.VUE_APP_API_URL
import AuthAgreement from './AuthAgreement'
import poneConfirm from './poneConfirm'
import { mapMutations } from 'vuex'
import { CUSTOMER_TYPE, JOIN_TYPE } from 'Configs/user'
import { postLoginPersonal, postJoinPersonal } from 'Api/modules'
import { authCheck, setAccessTokenToCookie } from 'Utils/auth'
import joinMixin from 'Mixins/join'
import {
  getFCMToken,
  sendMessageToApp,
  searchAddressGetResult,
} from 'Utils/message'

export default {
  components: {
    AuthAgreement,
    poneConfirm,
  },
  mixins: [joinMixin],
  props: {
    pass: {
      type: String,
    },
    passId: {
      type: String,
    },
    passEmail: {
      type: String,
    },
    passName: {
      type: String,
    },
    passGender: {
      type: String,
    },
    passToken: {
      type: String,
    },
    passPhoneNo: {
      type: String,
    },
    passBirthDay: {
      type: String,
    },
  },
  data() {
    return {
      userId: '',
      //userNm: '',
      userPw: '',
      userPwRepeat: '',
      address: '',
      addressDetail: '',
      region1DepthName: '',
      region2DepthName: '',
      region3DepthName: '',
      customerType: CUSTOMER_TYPE[0].value, //개인회원 '1' 고정값
      recommenderPhone: this.$route.query.recommenderPhone
        ? this.$route.query.recommenderPhone
        : '',
      checkEmail: 'N',
      checkSms: 'N',
      checkOpen: 'N',
      checkUserInfo: 'N',
      check3rd: 'N',
      //joinType: '',
      joinPath: this.isMobileDevice(),
      retentionPeriod: '',
      isValid: false,
      //checkMobile: '',

      isNaver: false,
      isKakao: false,

      //email: '', // 이메일은 회원가입에서 생략한다. --> 정보수정하는 곳에서 추가해야 한다.
      //phone: '',

      certedUserNm: '',
      certedPhone: '',
      certedEmail: '',
      certedGender: '',
      certedBirthday: '',
      certedNation: '',

      affiliatedCompany: '', //소속회사명

      isCertCompleted: false,
      isPhoneWrap: true,
      fcmToken: '',
      token: '',
    }
  },
  computed: {
    joinType() {
      if (this.isNaver) return JOIN_TYPE.NAVER
      else if (this.isKakao) return JOIN_TYPE.KAKAO
      else return JOIN_TYPE.HOGOO
    },
    checkMobile() {
      var varUA = navigator.userAgent.toLowerCase() //userAgent 값 얻기
      var mobile = /iphone|ipad|ipod|android/i.test(
        navigator.userAgent.toLowerCase(),
      )
      //console.log(mobile)
      if (mobile && varUA.indexOf('android') > -1) {
        //모바일 처리
        //alert('모바일')
        // alert('안드로이드')
        return false
      } else if (
        mobile &&
        (varUA.indexOf('iphone') > -1 ||
          varUA.indexOf('ipad') > -1 ||
          varUA.indexOf('ipod') > -1)
      ) {
        //alert('아이폰')
        return true
      } else {
        //alert('아이폰')
        //아이폰, 안드로이드 외
        return false
      }
    },
  },
  watch: {
    // isNaver() {
    //   if (this.isNaver === true) {
    //     this.$refs['poneConfirm'].$refs['userNm'].attr('readonly', false)
    //   }
    // },
    userId(id) {
      this.userIdWatch(id)
    },
    userPw(pw) {
      this.pwWatch(pw)
    },
    userPwRepeat(pw) {
      this.pwRpWatch(pw)
    },
  },
  // updated() {
  //   if (this.isNaver || this.isKakao) {
  //     console.log(this.$refs['poneConfirm'].$refs['userNm'])

  //   }
  // },
  methods: {
    ...mapMutations(['SET_IS_LOGIN', 'SET_ACCOUNT_USER_ID', 'SET_FCM_TOKEN']),
    parentsNm(ChildUserNm) {
      // this.certedUserNm = ChildUserNm
      this.certedUserNm = ChildUserNm == '' ? this.certedUserNm : ChildUserNm
    },
    naverLogin() {
      if (this.isApp) {
        location.href = URL + '/oauth2/authorization/naver'
      } else {
        window.open(
          URL + '/oauth2/authorization/naver',
          'naver join',
          'width=600, height=800',
        )
      }
    },
    kakaoLogin() {
      if (this.isApp) {
        location.href = URL + '/oauth2/authorization/kakao' //이동시켜서 인증 후 아래 created에서 query 처리한다.
      } else {
        window.open(
          URL + '/oauth2/authorization/kakao',
          'kakao join',
          'width=600, height=800',
        )
      }
    },
    onAgreementChanged(value) {
      const {
        checkOpen,
        checkUserInfo,
        check3rd,
        checkEmail,
        checkSms,
        retentionPeriod,
      } = value
      this.checkOpen = checkOpen ? 'Y' : 'N'
      this.checkUserInfo = checkUserInfo ? 'Y' : 'N'
      this.check3rd = check3rd ? 'Y' : 'N'
      this.checkEmail = checkEmail ? 'Y' : 'N'
      this.checkSms = checkSms ? 'Y' : 'N'
      this.retentionPeriod = retentionPeriod
    },

    //AuthAgreement로부터 수신 받는 본인인증 정보
    onCertCompleted({ userNm, phone, gender, birthday, nation }) {
      this.certedUserNm = userNm
      this.certedPhone = phone
      this.certedGender = gender
      this.certedBirthday = birthday
      this.certedNation = nation
      this.$nextTick(() => {
        this.$refs['userId'].focus()
      })
      this.isCertCompleted = true
      this.isPhoneWrap = false
      this.$toasted.show('본인인증 완료')
    },
    async getAndSetFcmToken() {
      if (this.isApp) {
        const fcmToken = await getFCMToken() //앱이 아닌 경우 '' 빈값을 수신한다.
        return fcmToken
      }
      return ''
    },
    // 회원가입
    async requestJoin() {
      if (!this.validCheck()) return

      if (!this.isKakao && !this.isNaver && !this.isCertCompleted) {
        this.$toasted.error(
          '본인인증이 되지 않았습니다. 본인인증 실시 후 다시 시도 해주세요.',
        )
        return
      }

      const fcmToken = await this.getAndSetFcmToken()
      //sendMessageToApp('LOG', { fcmToken: fcmToken })

      const saveData = {
        userId: this.userId,
        userNm: this.certedUserNm,
        userPw: this.userPw,
        address: this.address,
        addressDetail: this.addressDetail,
        region1DepthName: this.region1DepthName ? this.region1DepthName : '',
        region2DepthName: this.region2DepthName ? this.region2DepthName : '',
        region3DepthName: this.region3DepthName ? this.region3DepthName : '',
        customerType: this.customerType,
        recommenderPhone: this.recommenderPhone,
        checkEmail: this.checkEmail,
        checkSms: this.checkSms,
        checkOpen: this.checkOpen,
        checkUserInfo: this.checkUserInfo,
        check3Rd: this.check3rd,
        joinType: this.joinType,
        joinPath: this.joinPath,
        retentionPeriod: this.retentionPeriod,
        email: this.certedEmail,
        phone: this.certedPhone.split('-').join(''),
        affiliatedCompany: this.affiliatedCompany,
        checkMobile: this.checkMobile,

        gender: this.certedGender,
        birthday: this.certedBirthday,
        nation: this.certedNation,
        fcmToken: fcmToken,
      }
      console.log('VUE', saveData)
      console.log('VUE', this.certedAddress)
      await this.parentsNm()
      const res = await postJoinPersonal(saveData)
      try {
        if (res.status == 0) {
          //로그인 처리
          if (!this.userPw) this.userPw = this.userId
          const res2 = await postLoginPersonal({
            userId: this.userId,
            userPw: this.userPw,
            loginType: 'P',
            fcmToken: fcmToken,
          })

          //로그인 성공
          if (res2.status === 0) {
            console.info(
              'Personal => isCertCompleted accessToken : ',
              res2.message,
            )
            this.token = res2.message
            setAccessTokenToCookie(this.token)
            this.SET_ACCOUNT_USER_ID(this.userId)
            this.SET_IS_LOGIN(true)
          }

          //앱 로그인 유지 처리
          if (this.isApp) {
            //로그인 유지하도록 유저 정보를 앱으로 전송
            sendMessageToApp('keepLogin', {
              userId: this.userId,
              userPw: this.userPw,
              loginType: 'P',
            })
          }

          this.$toasted.show(
            '호구153에 회원가입이 되셨습니다.' +
              `\n` +
              '기본 정보 페이지로 이동합니다.',
          )
          //로그인 페이지로 이동
          // this.$router.push('/login')
          this.$router.push({
            // name: 'joinResult',
            name: 'mypageSmartMatch',
            params: {
              pass: this.joinType,
              passId: this.userId,
              passName: this.certedUserNm,
              passToken: this.token,
              passLoginType: 'P',
            },
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
    findAddress() {
      if (this.isApp) {
        searchAddressGetResult(({ sido, sigungu, bname, address }) => {
          this.region1DepthName = sido
          this.region2DepthName = sigungu
          this.region3DepthName = bname
          this.address = address
          this.addressDetail = ''
        })
      } else {
        //카카오 지도 발생
        new window.daum.Postcode({
          oncomplete: data => {
            //선택시 입력값 세팅
            const { sido, sigungu, bname, address } = data
            this.region1DepthName = sido
            this.region2DepthName = sigungu
            this.region3DepthName = bname
            this.address = address
            this.addressDetail = ''
            console.error(sido, sigungu, bname, address)
          },
        }).open()
      }
    },
  },
  created() {
    if (this.isApp) {
      //본인인증해서 다시 돌아온 경우 : 값 세팅
      this.setPersonalDataFromLocalStorage()
      if (!this.$route.query) return

      //카카오/네이버 인증으로 회원가입하는 경우
      //로그인하는 경우는 웹과 다르게 새창에서 처리되지 않기 때문에 Login.vue에서 처리된다.
      if (this.$route.query.userInfo) {
        const {
          provider,
          id,
          email,
          name,
          gender,
          birthday,
          phoneNo,
          phone_number,
          birthyear,
        } = JSON.parse(this.$route.query.userInfo)
        // sendMessageToApp('LOG', { phoneNo: phoneNo })
        // sendMessageToApp('LOG', { phone_number: phone_number })
        this.isKakao = provider === 'KAKAO' ? true : false
        this.isNaver = provider === 'NAVER' ? true : false

        this.userId = id
        this.certedEmail = email
        this.certedUserNm = name
        this.token = this.$route.query.token
        // this.$refs['poneConfirm'].$refs['userNm'].attr('readonly', false)
        if (this.isKakao) {
          this.certedBirthday = birthyear + birthday || ''
          this.certedPhone = phone_number.replace(/\+82 /gi, '0') || ''
        } else {
          this.certedBirthday = birthday || ''
          // naver 는 앱에서만 - 이 담겨져서 온다. 이상?
          this.certedBirthday = this.certedBirthday.split('-').join('')
          this.certedPhone = phoneNo ? phoneNo : ''
        }
        let convertGenderVal = ''
        if (gender) convertGenderVal = gender === 'MALE' ? '0' : '1'
        this.certedGender = convertGenderVal
        // this.certedBirthday = birthday.replace(/-/gi, '') || ''
        //sendMessageToApp('LOG', { birthday: this.certedBirthday })
        // this.certedPhone = phoneNo || ''
        //sendMessageToApp('LOG', { certedPhone: this.certedPhone })
      }
    }
    //웹인 경우 : 카카오, 네이버 인증 결과 처리
    else {
      window.addEventListener('message', async msg => {
        let res
        if (
          msg.data.name === 'KAKAO_REDIRECT' ||
          msg.data.name === 'NAVER_REDIRECT' ||
          msg.data.name === 'LOGIN_REDIRECT' //이미 회원가입 완료된 경우
        ) {
          const { result, userInfo, token } = msg.data
          //회원가입이 필요한 경우 회원가입 페이지로 props와 함께 전달
          if (result === 'JOIN') {
            const {
              provider,
              id,
              email,
              name,
              gender,
              birthday,
              phoneNo,
              phone_number,
              birthyear,
            } = userInfo

            //카카오 네이버 구분
            this.isKakao = provider === 'KAKAO' ? true : false
            this.isNaver = provider === 'NAVER' ? true : false

            this.userId = id
            this.certedEmail = email
            this.certedUserNm = name
            this.token = token
            // this.certedGender = gender
            let convertGenderVal = ''
            if (gender) convertGenderVal = gender === 'MALE' ? '0' : '1'
            this.certedGender = convertGenderVal
            if (this.isKakao) {
              this.certedBirthday = birthyear + birthday || ''
              this.certedPhone = phone_number
                ? phone_number.replace(/\+82 /gi, '0')
                : ''
            } else {
              this.certedBirthday = birthday ? birthday.replace(/-/gi, '') : ''
              // this.certedBirthday = birthday.split('-').join('')
              this.certedPhone = phoneNo ? phoneNo.split('-').join('') : ''
            }
          }
          //이미 가입된 회원인 겨우 토큰 저장하고 메인 페이지로 이동
          else {
            setAccessTokenToCookie(token)
            res = await authCheck()
            this.$store.commit('SET_IS_LOGIN', true)
            this.$store.commit('SET_ACCOUNT_INFO', res)
            //location.href = '/'
            this.$router.push('/')
          }
        }
        return
      })

      //로그인 페이지에서 카카오/네이버 인증 후 회원가입으로 유입된 경우
      if (this.passId) this.userId = this.passId
      if (this.passName) this.certedUserNm = this.passName
      if (this.passEmail) this.certedEmail = this.passEmail
      if (this.passGender) this.certedGender = this.passGender
      if (this.passPhoneNo) this.certedPhone = this.passPhoneNo
      if (this.passToken) this.token = this.passToken
      if (this.passBirthDay)
        this.certedBirthday = this.passBirthDay.split('-').join('')

      if (this.pass) {
        if (this.pass === 'KAKAO') {
          this.isKakao = true
          this.isNaver = false
        } else {
          this.isKakao = false
          this.isNaver = true
        }
      }
    }
  },
}
</script>
<style></style>
