<template>
  <div>
    <h2 class="h2-join">기업정보</h2>
    <div class="form-wrap">
      <pone-confirm
        :isCertCompleted="isCertCompleted"
        :isPhoneWrap="isPhoneWrap"
        @agreementChanged="onAgreementChanged"
        @certCompleted="onCertCompleted"
        @mobileCertRequest="onMobileCertRequestEnterprise"
      ></pone-confirm>
      <div class="input-form" v-if="isCertCompleted">
        <div class="t-head">아이디 <i class="required"></i></div>
        <input
          v-model="userId"
          type="text"
          class="text"
          ref="userId"
          placeholder="4 ~ 20자의 영문, 숫자와 특수문자(_)만 사용 가능합니다."
          @blur="chkDupUserId"
        />
      </div>
      <div class="input-form" v-if="isCertCompleted">
        <div class="t-head">비밀번호 <i class="required"></i></div>
        <input
          v-model="userPw"
          type="password"
          class="text"
          ref="userPw"
          placeholder="영문자/숫자/특수문자를 2가지 이상 조합하여 8자리 이상 입력"
          @blur="chkPwIsValid"
        />
      </div>
      <div class="input-form" v-if="isCertCompleted">
        <div class="t-head">비밀번호 확인 <i class="required"></i></div>
        <input
          v-model="userPwRepeat"
          type="password"
          class="text"
          ref="userPwRepeat"
          placeholder=""
          @blur="chkPwIsEqual"
        />
      </div>
      <div class="input-form" v-if="isCertCompleted" @click="findAddress">
        <div class="t-head">주소 <i class="required"></i></div>
        <div class="address-form">
          <input
            v-model="address"
            type="text"
            class="text addr1"
            ref="address"
            readonly
          />
          <button type="button" class="btn-func">주소찾기</button>
        </div>
      </div>
      <div class="input-form" v-if="isCertCompleted">
        <div class="t-head">나머지 주소 <i class="required"></i></div>
        <input
          v-model="addressDetail"
          type="text"
          class="text inp addr2"
          ref="addressDetail"
        />
      </div>
      <div class="input-form" v-show="isCertCompleted">
        <div class="t-head">기업구분 선택 <i class="required"></i></div>
        <!-- <select
          v-model="customerType"
          name=""
          id=""
          class="select"
          ref="customerType"
        >
          <option value="">선택</option>
          <option
            v-for="option in customerTypeList"
            :key="option.name"
            :value="option.value"
          >
            {{ option.name }}
          </option>
        </select> -->
        <div id="CompanyInformationCon">
          <label
            :for="option.id"
            v-for="option in customerTypeList"
            :key="option.name"
            :value="option.value"
          >
            <input
              type="radio"
              :id="option.id"
              name="CompanyInformation"
              v-model="customerType"
              ref="customerType"
              :value="option.value"
            />
            <p>
              <span class="t-head">{{ option.name }} : </span
              ><span>{{ option.text }}</span>
            </p></label
          >
        </div>
      </div>
      <div class="input-form" v-if="isCertCompleted">
        <div class="t-head">사업자등록번호 <i class="required"></i></div>
        <input
          v-model="bizNo"
          type="text"
          class="text"
          placeholder="사업자등록번호"
          ref="bizNo"
          @blur="chkDupBizNo"
        />
      </div>
      <div class="input-form" v-if="isCertCompleted">
        <div class="t-head">기업명 <i class="required"></i></div>
        <input
          v-model="companyNm"
          type="text"
          class="text"
          placeholder="기업명"
          ref="companyNm"
        />
      </div>
      <div class="input-form" v-if="isCertCompleted">
        <div class="t-head">대표자명 <i class="required"></i></div>
        <input
          v-model="president"
          type="text"
          class="text"
          placeholder="대표자명"
          ref="president"
        />
      </div>
      <!--div class="input-form">
        <div class="t-head">연락처 <i class="required"></i></div>
        <input
          v-model="phone"
          type="text"
          class="text"
          ref="phone"
          placeholder="'-' 없이 입력"
          :readonly="isCertCompleted"
        />
      </div>
      <div class="input-form">
        <div class="t-head">이메일 <i class="required"></i></div>
        <input
          v-model="email"
          type="text"
          class="text"
          placeholder=""
          ref="email"
        />
      </div>
      <div-- class="input-form">
        <div class="t-head">추천인 아이디</div>
        <input
          v-model="recommenderId"
          type="text"
          class="text"
          placeholder=""
        />
      </div-->
    </div>
    <auth-agreement
      @agreementChanged="onAgreementChanged"
      @certCompleted="onCertCompleted"
      @mobileCertRequest="onMobileCertRequestEnterprise"
    ></auth-agreement>
    <button class="btn __join" @click="requestJoin">회원가입</button>
  </div>
</template>

<script>
import AuthAgreement from './AuthAgreement'
import poneConfirm from './poneConfirm'
import { CUSTOMER_TYPE_FULL, JOIN_TYPE } from 'Configs/user'
import { postJoinCompany, postLoginCompany } from 'Api/modules'
import joinMixin from 'Mixins/join'
import { mapMutations } from 'vuex'
import {
  getFCMToken,
  sendMessageToApp,
  searchAddressGetResult,
} from 'Utils/message'
import { setAccessTokenToCookie } from 'Utils/auth'

export default {
  components: {
    AuthAgreement,
    poneConfirm,
  },
  mixins: [joinMixin],
  data() {
    return {
      userId: '',
      userPw: '',
      userPwRepeat: '',
      address: '',
      addressDetail: '',
      region1DepthName: '',
      region2DepthName: '',
      region3DepthName: '',
      customerType: '',
      recommenderId: '',
      checkEmail: 'N',
      checkSms: 'N',
      checkOpen: 'N',
      checkUserInfo: 'N',
      check3rd: 'N',
      //joinType: '',
      joinPath: this.isMobileDevice(),
      retentionPeriod: '',
      companyNm: '',
      president: '',
      bizNo: '',

      customerTypeList: JSON.parse(JSON.stringify(CUSTOMER_TYPE_FULL)),

      //@TODO SNS 로그인 처리 필요
      isNaver: false,
      isKakao: false,

      //email: '',
      //phone: '',
      certedUserNm: '',
      certedPhone: '',
      certedEmail: '',
      certedGender: '',
      certedBirthday: '',
      certedNation: '',

      isCertCompleted: false,
      isPhoneWrap: true,
      fcmToken: '',
      token: '',
    }
  },
  computed: {
    joinType() {
      if (this.isNaver) return JOIN_TYPE.NAVER
      else if (this.isKakao) return JOIN_TYPE.KAKAO
      else return JOIN_TYPE.HOGOO
    },
  },
  watch: {
    userId(id) {
      this.userIdWatch(id)
    },
    bizNo(bizNo) {
      this.bizNoWatch(bizNo)
    },
    // isCertCompleted() {
    //   var inputId = window.$('input[id="franchisee"]')
    //   if (this.isCertCompleted == true) inputId.prop('checked', true)
    // },
  },
  // updated() {
  //   this.$nextTick(() => {
  //     var inputId = window.$('input[id="franchisee"]')
  //     if (this.isCertCompleted == true) inputId.prop('checked', true)
  //   })
  // },
  methods: {
    ...mapMutations(['SET_IS_LOGIN', 'SET_ACCOUNT_USER_ID', 'SET_FCM_TOKEN']),
    findAddress() {
      if (this.isApp) {
        searchAddressGetResult(({ sido, sigungu, bname, address }) => {
          this.region1DepthName = sido
          this.region2DepthName = sigungu
          this.region3DepthName = bname
          this.address = address
          this.addressDetail = ''
        })
      } else {
        //카카오 지도 발생
        new window.daum.Postcode({
          oncomplete: data => {
            //선택시 입력값 세팅
            const { sido, sigungu, bname, address } = data
            this.region1DepthName = sido
            this.region2DepthName = sigungu
            this.region3DepthName = bname
            this.address = address
            this.addressDetail = ''
            console.error(sido, sigungu, bname, address)
          },
        }).open()
      }
    },
    onCertCompleted({ userNm, phone, gender, birthday, nation }) {
      var inputId = window.$('input[id="franchisee"]')
      this.certedUserNm = userNm
      this.certedPhone = phone
      this.certedGender = gender
      this.certedBirthday = birthday
      this.certedNation = nation
      this.isCertCompleted = true
      this.isPhoneWrap = false
      inputId.prop('checked', true)
      this.$toasted.show('본인인증 완료')
    },
    onAgreementChanged(value) {
      const {
        checkOpen,
        checkUserInfo,
        check3rd,
        checkEmail,
        checkSms,
        retentionPeriod,
      } = value
      this.checkOpen = checkOpen ? 'Y' : 'N'
      this.checkUserInfo = checkUserInfo ? 'Y' : 'N'
      this.check3rd = check3rd ? 'Y' : 'N'
      this.checkEmail = checkEmail ? 'Y' : 'N'
      this.checkSms = checkSms ? 'Y' : 'N'
      this.retentionPeriod = retentionPeriod
    },
    async getAndSetFcmToken() {
      if (this.isApp) {
        const fcmToken = await getFCMToken() //앱이 아닌 경우 '' 빈값을 수신한다.
        return fcmToken
      }
      return ''
    },
    async requestJoin() {
      const regType = /^[A-Za-z0-9_]{4,20}$/
      if (!regType.test(this.userId)) {
        this.$toasted.error(
          '4 ~ 20자의 영문, 숫자와 특수문자(_)만 사용 가능합니다.',
        )
        this.$refs['userId'].focus()
        return false
      }
      if (!this.userPw) {
        this.$toasted.error('비밀번호를 입력해주세요.')
        this.$refs['userPw'].focus()
        return false
      }

      // 비밀번호 정합성 체크, 영문자/숫자/특수문자 2가지 아상 조합 8자리 이상
      if (!this.validPassword(this.userPw)) {
        this.$toasted.error(
          '영문자/숫자/특수문자를 2가지 이상 조합하여 8자리 이상 입력해주세요.',
        )
        this.$refs['userPw'].focus()
        this.$refs['userPw'].value = ''
        this.$refs['userPwRepeat'].value = ''
        return false
      }

      //비밀번호 확인 입력 체크
      if (!this.userPwRepeat) {
        this.$refs['userPwRepeat'].focus()
        this.$toasted.error('비밀번호 확인을 입력해주세요.')
        return false
      }

      //비밀번호 확인 일치 체크
      if (!this.validPasswordRepeat()) {
        this.$toasted.error('비밀번호가 일치하지 않습니다')
        this.$refs['userPwRepeat'].focus()
        return false
      }

      if (!this.address) {
        this.$toasted.error('주소를 입력해주세요.')
        this.$refs['address'].focus()
        this.findAddress()
        return false
      }

      if (!this.addressDetail) {
        this.$toasted.error('나머지 주소를 입력해주세요.')
        this.$refs['addressDetail'].focus()
        return false
      }
      // 기업회원 유형 확인
      if (!this.customerType) {
        this.$toasted.error('기업구분을 선택해주세요.')
        this.$refs['customerType'].focus()
        return false
      }
      // 사업자 번호 체크
      if (!this.bizNo) {
        this.$toasted.error('사업자번호를 입력해주세요.')
        this.$refs['bizNo'].focus()
        return false
      }
      if (!this.validBizNo()) {
        this.$toasted.error('사업자번호 형식이 올바르지 않습니다.')
        this.$refs['bizNo'].focus()
        return false
      }
      // 대표자명 정합성
      if (!this.companyNm) {
        this.$toasted.error('기업명을 입력해주세요.')
        this.$refs['companyNm'].focus()
        return false
      }
      // 대표자명 정합성
      if (!this.president) {
        this.$toasted.error('대표자명을 입력해주세요.')
        this.$refs['president'].focus()
        return false
      }
      if (!this.validCheck()) return

      if (!this.isCertCompleted) {
        this.$toasted.error(
          '본인인증이 되지 않았습니다. 본인인증 실시 후 다시 시도 해주세요.',
        )
        return
      }
      const fcmToken = await this.getAndSetFcmToken()
      //sendMessageToApp('LOG', { fcmToken: fcmToken })
      const saveData = {
        userId: this.userId,
        userPw: this.userPw,
        customerType: this.customerType,
        recommenderId: this.recommenderId,

        address: this.address,
        addressDetail: this.addressDetail,
        region1DepthName: this.region1DepthName,
        region2DepthName: this.region2DepthName,
        region3DepthName: this.region3DepthName,

        checkEmail: this.checkEmail,
        checkSms: this.checkSms,
        checkOpen: this.checkOpen,
        checkUserInfo: this.checkUserInfo,
        check3Rd: this.check3rd,
        retentionPeriod: this.retentionPeriod,

        joinType: this.joinType,
        joinPath: this.joinPath,
        companyNm: this.companyNm,
        president: this.president,
        bizNo: this.bizNo.split('-').join(''),

        userNm: this.certedUserNm,
        email: this.certedEmail,
        phone: this.certedPhone,
        gender: this.certedGender,
        birthday: this.certedBirthday,
        nation: this.certedNation,
        fcmToken: fcmToken,
      }

      try {
        const res = await postJoinCompany(saveData)
        if (res.status == 0) {
          //로그인 처리
          const res2 = await postLoginCompany({
            userId: this.userId,
            userPw: this.userPw,
            loginType: 'C',
            fcmToken: fcmToken,
          })

          //로그인 성공
          if (res2.status === 0) {
            //앱 로그인 유지 처리
            if (this.isApp) {
              //로그인 유지하도록 유저 정보를 앱으로 전송
              sendMessageToApp('keepLogin', {
                userId: this.userId,
                userPw: this.userPw,
                loginType: 'C',
              })
            }

            this.token = res2.message
            setAccessTokenToCookie(this.token)
            this.SET_ACCOUNT_USER_ID(this.userId)
            this.SET_IS_LOGIN(true)
          }

          this.$toasted.show('호구153에 회원가입이 되셨습니다.')
          //로그인 페이지로 이동
          //this.$router.push('/login')
          this.$router.push({
            name: 'joinResult',
            params: {
              pass: this.joinType,
              passId: this.userId,
              passName: this.certedUserNm,
              passToken: this.token,
              passLoginType: 'C',
            },
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
  },
  created() {
    if (this.isApp) this.setEnterpriseDataFromLocalStorage() //본인인증해서 다시 돌아온 경우 : 값 세팅
  },
}
</script>

<style></style>
