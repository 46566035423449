<template>
  <div>
    <div class="form-wrap _phone-wrap">
      <hr />
      <p class="check-wrap">
        <input
          type="checkbox"
          id="c1"
          v-model="allCheck"
          @click="allCheckClick"
        />
        <label for="c1">전체동의</label>
      </p>
      <div class="check-box type01">
        <p class="check-wrap" :class="{ on: isUserInfoShow }">
          <input
            type="checkbox"
            id="c1-1"
            v-model="check.checkUserInfo"
            ref="checkUserInfo"
          />
          <label>서비스 이용약관 <span class="required"></span> </label>
          <i class="el-icon-arrow-down" @click="toggleUserInfo"></i>
        </p>
        <div class="agree-item-wrap" v-html="memberTerms"></div>
      </div>
      <div class="check-box type01">
        <p class="check-wrap" :class="{ on: isOpenShow }">
          <input
            type="checkbox"
            id="c1-2"
            v-model="check.checkOpen"
            ref="checkOpen"
          />
          <label>개인 정보보호 정책 <span class="required"></span> </label>
          <i class="el-icon-arrow-down" @click="toggleOpen"></i>
        </p>
        <div class="agree-item-wrap" v-html="privacyTerms"></div>
      </div>
      <div class="check-box type01">
        <p class="check-wrap" :class="{ on: is3rdShow }">
          <input
            type="checkbox"
            id="c1-2"
            v-model="check.check3rd"
            ref="check3rd"
          />
          <label
            >개인정보 제3자 제공 및 위탁사항 이용약관
            <!--span class="required"></!--span-->
          </label>
          <i class="el-icon-arrow-down" @click="toggle3rd"></i>
        </p>
        <div class="agree-item-wrap">
          <dl>
            <dt>1. 개인정보 제3자 제공</dt>
            <dd>
              hogoo153은 「개인정보 보호정책」에서 고지한 범위 내에서만 개인정
              보를 이용하며, 원칙적으로 이용자의 개인정보를 제3자에게 제공하지
              않습니다. 다만, 아래의 경우에는 예외로 합니다.<br /><br />
              가. 이용자가 서비스 이용중 제3자 제공에 동의(수락)한 경우<br />
              이용자 동의 후 개인정보 제공이 발생하는 경우<br /><br />
              나. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
              방법에 따라 수사기관의 요구가 있는 경우 회원께서는 개인정보의
              제3자 제공에 대해, 동의하지 않을 수 있고 언제든지 제3자 제공
              동의를 철회할 수 있습니다. 다만, 제3자 제공에 기반한 관련된 일부
              서비스의 이용이 제한될 수 있습니다.
            </dd>
            <dt>2. 개인정보 처리위탁</dt>
            <dd>
              회사는 개인정보의 처리와 관련하여 아래와 같이 업무를 위탁하고
              있으며, 관계법령에 따라 위탁 처리되는 개인정보가 안전하게 관리될
              수 있도록 필요한 조치를 취하고 있습니다. 또한, 위탁 처리하는
              정보는 서비스 제공에 필요한 최소한의 범위에 국한됩니다. 회사에서
              위탁처리 되고 있는 업무는 다음과 같고, 위탁사항이 변경되는 경우
              해당 사실을 알려드리겠습니다.<br /><br />
              결제처리: (주)스마트로<br />
              휴대폰 본인인증: 한국모바일인증(주)<br />
              카카오 알림톡: (주)앰앤와이즈<br />
              서비스 운영 업무: (주)다올비젼
            </dd>
          </dl>
        </div>
      </div>
      <p>마케팅 정보 수신 동의</p>
      <div class="check-box type01">
        <!-- <p class="check-wrap" :class="{ on: isEmailShow }"> -->
        <p class="check-wrap">
          <input type="checkbox" id="c1-3" v-model="check.checkEmail" />
          <label for="c1-3">마케팅 정보 수신 동의 - 이메일(선택)</label>
          <!-- <i class="el-icon-arrow-down" @click="toggleEmail"></i> -->
        </p>
        <!-- <div class="agree-item-wrap">
          <dl>
            <dt>제 1 조 (목적)</dt>
            <dd>
              본 약관은 (주)미디어윌네트웍스(이하 “회사”)가 운영하는 알바천국,
              간호잡, 강사닷컴 등의 온라인 웹사이트를 통하여 직업정보 관련 제반
              “서비스”를 제공함에 있어 이용 고객(이하 “회원”)과 “회사”간의 권리,
              의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
            </dd>
            <dt>제 2 조 (용어의 정의)</dt>
            <dd>
              본 약관에서 사용하는 용어의 정의는 아래와 같습니다. <br />①
              “사이트”라 함은 “회사”가 “회원”에게 “서비스”를 제공하기 위하여
              컴퓨터 및 휴대폰 단말기
            </dd>
          </dl>
        </div> -->
      </div>
      <div class="check-box type01">
        <!-- <p class="check-wrap" :class="{ on: isSmsShow }"> -->
        <p class="check-wrap">
          <input type="checkbox" id="c1-4" v-model="check.checkSms" />
          <label for="c1-4">마케팅 정보 수신 동의 - SMS/MMS(선택)</label>
          <!-- <i class="el-icon-arrow-down" @click="toggleSms"></i> -->
        </p>
        <!-- <div class="agree-item-wrap">
          <dl>
            <dt>제 1 조 (목적)</dt>
            <dd>
              본 약관은 (주)미디어윌네트웍스(이하 “회사”)가 운영하는 알바천국,
              간호잡, 강사닷컴 등의 온라인 웹사이트를 통하여 직업정보 관련 제반
              “서비스”를 제공함에 있어 이용 고객(이하 “회원”)과 “회사”간의 권리,
              의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
            </dd>
            <dt>제 2 조 (용어의 정의)</dt>
            <dd>
              본 약관에서 사용하는 용어의 정의는 아래와 같습니다. <br />①
              “사이트”라 함은 “회사”가 “회원”에게 “서비스”를 제공하기 위하여
              컴퓨터 및 휴대폰 단말기
            </dd>
          </dl>
        </div> -->
      </div>
      <hr />
      <div class="check-box terms">
        <span>정보보유기간</span>
        <div class="check-wrap">
          <div
            class="retention-item"
            v-for="retentionItem in RETENTION_PERIOD"
            :key="retentionItem.name"
          >
            <input
              v-model="check.retentionPeriod"
              type="radio"
              :id="retentionItem.name"
              name="c2"
              :value="retentionItem.value"
            />
            <label :for="retentionItem.name">{{ retentionItem.name }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RETENTION_PERIOD } from 'Configs/user'
import joinMixin from 'Mixins/join'
import { sendMessageToApp } from 'Utils/message'
import Cookies from 'js-cookie'
import { personal, enterprise, privacy } from 'Configs/terms'
import { GENDER_OPTION } from 'Configs/registJob'
export default {
  mixins: [joinMixin],

  props: {
    isPersonal: {
      type: Boolean,
      default: false,
    },
    isSns: {
      type: Boolean,
      default: false,
    },
    // userNm: {
    //   type: String,
    // },
    // phone: {
    //   type: String,
    // },
    certedUserNm: {
      type: String,
    },
    certedEmail: {
      type: String,
    },
    certedGender: {
      type: String,
    },
    certedBirthday: {
      type: String,
    },
    certedPhone: {
      type: String,
    },
    userId: {
      type: String,
    },
    userPw: {
      type: String,
    },
    userPwRepeat: {
      type: String,
    },
    customerType: {
      type: Number,
    },
    bizNo: {
      type: String,
    },
    companyNm: {
      type: String,
    },
    president: {
      type: String,
    },
  },
  data() {
    return {
      GENDER_OPTION: Object.freeze(
        JSON.parse(JSON.stringify(GENDER_OPTION)).splice(1, 2),
      ),
      personalTerms: Object.freeze(personal),
      enterpriseTerms: Object.freeze(enterprise),
      privacyTerms: Object.freeze(privacy),

      RETENTION_PERIOD: RETENTION_PERIOD,
      allCheck: false,
      check: {
        checkUserInfo: false,
        checkOpen: false,
        check3rd: false,
        retentionPeriod: '', //RETENTION_PERIOD[0].value,
        checkEmail: false,
        checkSms: false,
      },
      exceptKey: null,
      modal: false,

      isUserInfoShow: false,
      isOpenShow: false,
      is3rdShow: false,
      // isEmailShow: false,
      // isSmsShow: false,
      isCertCompleted: false,
      isNaver: false,
      isKakao: false,
    }
  },
  computed: {
    memberTerms() {
      if (this.isPersonal) return this.personalTerms
      return this.enterpriseTerms
    },
  },
  watch: {
    check: {
      deep: true,
      handler(newVal) {
        let checkCount = 0
        //전체동의 되있는 경우 중 하나라도 체크해제하면 전체동의 해제
        Object.keys(newVal).forEach(key => {
          if (!newVal[key]) {
            this.allCheck = false
          } else {
            checkCount++
          }
        })
        //각각 모두 선택한 경우 전체동의 체크되도록 함
        if (checkCount === 5) this.allCheck = true
        this.$emit('agreementChanged', newVal)
      },
    },
  },
  methods: {
    toggleUserInfo() {
      this.isUserInfoShow = !this.isUserInfoShow
    },
    toggleOpen() {
      this.isOpenShow = !this.isOpenShow
    },
    toggle3rd() {
      this.is3rdShow = !this.is3rdShow
    },
    // toggleEmail() {
    //   this.isEmailShow = !this.isEmailShow
    // },
    // toggleSms() {
    //   this.isSmsShow = !this.isSmsShow
    // },

    //전체동의 선택
    allCheckClick() {
      this.allCheck = !this.allCheck
      this.toggleAll(this.allCheck)
    },
    toggleAll(value) {
      Object.keys(this.check).forEach(key => {
        if (key !== 'retentionPeriod') this.check[key] = value
      })
    },
    authEmail() {},

    defaultCertErrorToast() {
      this.$toasted.error(
        '본인 인증에 예기치 않은 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
      )
    },
  },
  created() {
    sendMessageToApp('LOG', {
      created: 'created',
      Cookies: Cookies.get('isApp'),
      isApp: this.isApp,
      query: this.$route.query,
      certNum: this.$route.query.certNum,
      rec_cert: this.$route.query.rec_cert,
    })

    //앱인 경우
    if (this.isApp) {
      if (!this.isKakao && !this.isNaver) {
        if (!this.$route.query.certNum || !this.$route.query.rec_cert) {
          // this.$toasted.error(
          //   '본인인증에 예기치 않은 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
          // )
          return
        }
        const { certNum, rec_cert } = this.$route.query
        this.processCert(certNum, rec_cert)
      }
    }
    //웹인 경우
    // else {
    //   window.addEventListener('message', async msg => {
    //     if (msg.data.name === 'KMCIS_REDIRECT') {
    //       const { certNum, rec_cert } = msg.data
    //       this.processCert(certNum, rec_cert)
    //     }
    //   })
    // }
  },
}
</script>

<style lang="scss" scoped>
.retention-item {
  display: flex;
  align-items: center;
}
.form-wrap > .input-form ul.check-wrap {
  display: flex;
  width: 100%;

  &.gender {
    margin-top: 0;
  }

  > li {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  > li > label {
    margin-left: 8px;
  }
}
input[type='checkbox'] {
  background: url(~assets/image/bg/bg-chk-off.svg) no-repeat !important;
}
input[type='checkbox']:checked {
  background: url(~assets/image/bg/bg-chk-on.svg) no-repeat !important;
}
input[type='checkbox']::-ms-check {
  background: url(~assets/image/bg/bg-chk-off.svg) no-repeat !important;
}
input[type='checkbox']:checked::-ms-check {
  background: url(~assets/image/bg/bg-chk-on.svg) no-repeat !important;
}
input[type='radio'] {
  background: url(~assets/image/bg/bg-rd-off.svg) no-repeat !important;
}
input[type='radio']:checked {
  background: url(~assets/image/bg/bg-rd-on.svg) no-repeat !important;
}
input[type='radio']::-ms-check {
  background: url(~assets/image/bg/bg-rd-off.svg) no-repeat !important;
}
input[type='radio']:checked::-ms-check {
  background: url(~assets/image/bg/bg-rd-on.svg) no-repeat !important;
}
</style>
