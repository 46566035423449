<template>
  <div class="_phone-wrap">
    <div v-if="!isSns" class="input-form">
      <div class="t-head" v-if="isPhoneWrap">
        {{ isPersonal ? '본인 인증' : '담당자 인증' }}
        <i class="required"></i>
      </div>
      <button v-if="isPhoneWrap" class="_phone" @click="authSms">
        핸드폰 인증
      </button>
    </div>

    <!-- 본인인증 결과 데이타를 이쪽으로 이동시킴 -->
    <div class="mt10"><p></p></div>
    <div class="input-form" v-if="isSns || isCertCompleted">
      <div class="t-head">이름</div>
      <input
        v-if="!isSns"
        v-model="userNm"
        id="userNm"
        name="userNm"
        type="text"
        class="text"
        ref="userNm"
        @input="changeName"
        readonly
      />
      <input
        v-if="isSns"
        v-model="userNm"
        id="userNm"
        name="userNm"
        type="text"
        class="text"
        ref="userNm"
        @input="changeName"
      />
    </div>
    <div class="input-form" v-if="isSns || isCertCompleted">
      <div class="t-head">연락처</div>
      <input v-model="phone" type="text" class="text" readonly />
    </div>
    <div class="input-form" v-if="isSns">
      <div class="t-head">이메일</div>
      <input
        v-model="email"
        type="text"
        class="text email"
        ref="certedEmail"
        readonly
      />
    </div>
    <div class="input-form" v-if="isSns || isCertCompleted">
      <div class="t-head">성별</div>
      <ul class="check-wrap gender" style="width: 100%">
        <li v-for="(genderItem, idx) in GENDER_OPTION" :key="genderItem.name">
          <input
            v-model="gender"
            type="radio"
            class="checkbox"
            :ref="'genderCode_' + idx"
            :id="genderItem.name"
            :value="genderItem.value"
            disabled
          />
          <label :for="genderItem.name">{{ genderItem.name }}</label>
        </li>
      </ul>
      <!-- <input v-model="gender" type="text" class="text" readonly /> -->
    </div>
    <div class="input-form" v-if="isSns || isCertCompleted">
      <div class="t-head">생년월일</div>
      <!--안드로이드-->
      <!--아이폰에 보여질 input-->
      <input
        v-bind:class="{ on: !checkMobile }"
        v-model="birthday"
        type="text"
        class="text iponebirth"
      />
      <!--아이폰-->
      <!--안드로이드에 보여질 input-->
      <input
        v-bind:class="{ on: checkMobile }"
        v-model="birthday"
        type="text"
        class="text androidbirth"
        readonly
      />
    </div>
  </div>
</template>

<script>
import { postEncKey, postDecKey } from 'Api/modules'
import joinMixin from 'Mixins/join'
import { sendMessageToApp } from 'Utils/message'
import Cookies from 'js-cookie'
import { personal, enterprise } from 'Configs/terms'
import { GENDER_OPTION } from 'Configs/registJob'
// import chardet from 'chardet'
export default {
  mixins: [joinMixin],

  props: {
    isPersonal: {
      type: Boolean,
      default: false,
    },
    isSns: {
      type: Boolean,
      default: false,
    },
    isCertCompleted: {
      type: Boolean,
      default: false,
    },
    isPhoneWrap: {
      type: Boolean,
      default: true,
    },
    // userNm: {
    //   type: String,
    // },
    // phone: {
    //   type: String,
    // },
    certedUserNm: {
      type: String,
    },
    certedEmail: {
      type: String,
    },
    certedGender: {
      type: String,
    },
    certedBirthday: {
      type: String,
    },
    certedPhone: {
      type: String,
    },
    userId: {
      type: String,
    },
    userPw: {
      type: String,
    },
    userPwRepeat: {
      type: String,
    },
    customerType: {
      type: Number,
    },
    bizNo: {
      type: String,
    },
    companyNm: {
      type: String,
    },
    president: {
      type: String,
    },
  },
  data() {
    return {
      GENDER_OPTION: Object.freeze(
        JSON.parse(JSON.stringify(GENDER_OPTION)).splice(1, 2),
      ),
      personalTerms: Object.freeze(personal),
      enterpriseTerms: Object.freeze(enterprise),

      allCheck: false,
      check: {
        checkUserInfo: false,
        checkOpen: false,
        check3rd: false,
      },
      exceptKey: null,
      modal: false,

      // isEmailShow: false,
      // isSmsShow: false,
      userNm: '',
      phone: '',
      email: '',
      gender: '',
      birthday: '',
      nation: '',
      // isCertCompleted: false,
      isNaver: false,
      isKakao: false,
    }
  },
  computed: {
    memberTerms() {
      if (this.isPersonal) return this.personalTerms
      return this.enterpriseTerms
    },
    checkMobile() {
      var varUA = navigator.userAgent.toLowerCase() //userAgent 값 얻기
      var mobile = /iphone|ipad|ipod|android/i.test(
        navigator.userAgent.toLowerCase(),
      )
      //console.log(mobile)
      if (mobile && varUA.indexOf('android') > -1) {
        //모바일 처리
        //alert('모바일')
        // alert('안드로이드')
        return false
      } else if (
        mobile &&
        (varUA.indexOf('iphone') > -1 ||
          varUA.indexOf('ipad') > -1 ||
          varUA.indexOf('ipod') > -1)
      ) {
        //alert('아이폰')
        return true
      } else {
        //alert('아이폰')
        //아이폰, 안드로이드 외
        return false
      }
    },
  },
  watch: {
    certedUserNm: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal !== this.userNm) this.userNm = newVal
      },
    },
    certedEmail: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal !== this.email) this.email = newVal
        // this.emailHandler
      },
    },
    certedGender: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal !== this.gender) this.gender = newVal
      },
    },
    certedBirthday: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal !== this.birthday) this.birthday = newVal
      },
    },
    certedPhone: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal !== this.phone) this.phone = newVal
      },
    },
  },
  updated() {
    if (this.isSns) {
      window.$('input[name=userNm]').attr('readonly', false)
      console.log(this.isApp)
    }
    if (this.isApp && this.isSns) {
      window.$('input[name=userNm]').attr('readonly', false)
      console.log(this.isApp)
    }
  },
  methods: {
    async changeName(ChildUserNm) {
      ChildUserNm = await this.userNm
      await this.$emit('changeName', ChildUserNm)
    },
    // emailchange() {
    //   if (this.isCertCompleted === true) {
    //     window.$('.email').attr('readonly', false)
    //   }
    // },
    async authSms() {
      //   if (!this.userId) {
      //     this.$toasted.error('아이디를 입력하세요')
      //     return false
      //   }
      //   if (!this.userPw) {
      //     this.$toasted.error('비밀번호를 입력하세요')
      //     return false
      //   }
      //   if (!this.userPwRepeat) {
      //     this.$toasted.error('비밀번호 확인을 입력하세요')
      //     return false
      //   }
      //   // 기업일 경우
      //   if (!this.isPersonal) {
      //     if (!this.customerType) {
      //       this.$toasted.error('기업구분을 입력하세요')
      //       return false
      //     }
      //     if (!this.bizNo) {
      //       this.$toasted.error('사업자번호를 입력하세요')
      //       return false
      //     }
      //     if (!this.companyNm) {
      //       this.$toasted.error('기업명을 입력하세요.')
      //       return false
      //     }
      //     if (!this.president) {
      //       this.$toasted.error('대표자명을 입력하세요.')
      //       return false
      //     }
      //   }
      // if (!this.userNm) {
      //   this.$toasted.error('이름을 입력해주세요.')
      //   return false
      // }

      // if (!this.phone) {
      //   this.$toasted.error('연락처를 입력해주세요.')
      //   return false
      // }

      // if (!this.validName()) {
      //   this.$toasted.error('이름 형식이 올바르지 않습니다.')
      //   return false
      // }

      // if (!this.validPhone()) {
      //   this.$toasted.error('연락처 형식이 올바르지 않습니다.')
      //   return false
      // }
      const res = await postEncKey() // 본인인증 요청
      if (res) {
        if (!this.isApp) {
          window.addEventListener(
            'message',
            msg => {
              if (msg.data.name === 'KMCIS_REDIRECT') {
                const { certNum, rec_cert } = msg.data
                try {
                  this.processCert(certNum, rec_cert)
                  return
                } catch (e) {
                  console.error(e)
                  return
                }
              }
            },
            { once: true },
          )
        }

        // this.openPopup(res.map.tr_cert)
        const reqKMCISForm = document.createElement('form')

        reqKMCISForm.target = 'KMCISWindow'
        reqKMCISForm.method = 'POST'

        const trCertInput = document.createElement('input')
        trCertInput.type = 'hidden'
        trCertInput.name = 'tr_cert'
        trCertInput.value = res.map.tr_cert
        reqKMCISForm.appendChild(trCertInput)

        const trUrlInput = document.createElement('input')
        trUrlInput.type = 'hidden'
        trUrlInput.name = 'tr_url'
        trUrlInput.value = document.location.href
        reqKMCISForm.appendChild(trUrlInput)

        document.body.appendChild(reqKMCISForm)

        // 모바일 앱일 경우 (변동사항 있을경우 추가 필요)
        if (this.isApp) {
          reqKMCISForm.target = ''
          sendMessageToApp('LOG', {
            message: 'mobileCertRequest',
          })
          this.$emit('mobileCertRequest')
        }
        // 모바일 앱이 아닐 경우 - 팝업(모바일 웹브라우저, 웹브라우저)
        else {
          reqKMCISForm.target = 'KMCISWindow'
          window.open(
            '',
            'KMCISWindow',
            'width=800, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250,',
          )
        }
        reqKMCISForm.action = 'https://www.kmcert.com/kmcis/web/kmcisReq.jsp'
        // reqKMCISForm.action = 'https://www.kmcert.com/kmcis/web/kmcisTot.jsp'
        reqKMCISForm.submit()
      }
      // this.isCertCompleted = true
    },
    authEmail() {},

    defaultCertErrorToast() {
      this.$toasted.error(
        '본인 인증에 예기치 않은 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
      )
    },

    decodeName(decodedName, nameValue) {
      try {
        // Use the decodeURI function to decode the ASCII encoded name
        // and assign the decoded value to the 'decodedName' data property
        decodedName = decodeURI(nameValue)
      } catch (error) {
        // If there's an error in decoding, handle it accordingly
        console.error('Error decoding name:', error)
      }
    },

    async processCert(certNum, rec_cert) {
      try {
        const res = await postDecKey({ certNum, rec_cert }) // 본인인증 요청

        sendMessageToApp('LOG', {
          postDecKeyResponse: res,
        })

        if (res) {
          //받을 수 있는 데이터 : 이름, 연락처(01012341234), 성별(남:0, 여:1), 생년월일(YYYYMMDD)
          const { name, phoneNo, gender, birthDay, nation } = res.map.data
          // const detectedEncoding = chardet.detect(name) //ASCII
          // const encoder = new TextEncoder()
          // const utf8EncodedData = encoder.encode(name) //195,128,195,147,195,129,195,184,195,135,195,181

          this.userNm = name
          this.phone = phoneNo
          // this.gender = String(gender) === '1' ? 'FEMALE' : 'MALE'
          this.gender = gender
          this.birthday = birthDay
          this.nation = nation

          sendMessageToApp('LOG', {
            result: `name - ${name}, phoneNo - ${phoneNo}, gender - ${gender}, birthday - ${birthDay}, nation - ${nation}`,
          })

          // TODO:: 본인인증이 된 값들은 입력값에 readonly 처리가 필요하다.
          // fixme:: 이미 본인인증 팝업으로 처리가 되므로, 다음 로직은 불필요함.
          // if (this.userNm == userNm && String(this.phone) == String(phone)) {
          //   this.$emit('certCompleted', { userNm, phone })
          // } else {
          //   if (this.userNm !== userNm) {
          //     this.$toasted.error(
          //       '본인 인증 정보의 이름과 회원님이 입력하신 이름이 다릅니다. 확인 후 다시 시도해주세요. ',
          //     )
          //     return
          //   }
          //   if (this.phone !== phone) {
          //     this.$toasted.error(
          //       '본인 인증 정보의 핸드폰 번호와 회원님이 입력하신 핸드폰 번호가 다릅니다. 확인 후 다시 시도해주세요. ',
          //     )
          //     return
          //   }
          // }
          this.$emit('certCompleted', {
            userNm: name,
            phone: phoneNo,
            gender: this.gender,
            birthday: this.birthday,
            nation: this.nation,
          })
        }
      } catch (e) {
        console.error(e)
        this.defaultCertErrorToast()
      }
    },
  },
  // updated() {
  //   if (this.isSns) {
  //     window.$('input[name=userNm]').attr('readonly', false)
  //   }
  // },
  created() {
    // this.emailHandler = this.emailchange
    sendMessageToApp('LOG', {
      created: 'created',
      Cookies: Cookies.get('isApp'),
      isApp: this.isApp,
      query: this.$route.query,
      certNum: this.$route.query.certNum,
      rec_cert: this.$route.query.rec_cert,
    })

    //앱인 경우
    if (this.isApp) {
      if (!this.isKakao && !this.isNaver) {
        if (!this.$route.query.certNum || !this.$route.query.rec_cert) {
          // this.$toasted.error(
          //   '본인인증에 예기치 않은 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
          // )
          return
        }
        const { certNum, rec_cert } = this.$route.query
        this.processCert(certNum, rec_cert)
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.retention-item {
  display: flex;
  align-items: center;
}
.form-wrap > .input-form ul.check-wrap {
  display: flex;
  width: 100%;

  &.gender {
    margin-top: 0;
  }

  > li {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  > li > label {
    margin-left: 8px;
  }
}
</style>
